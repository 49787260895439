import React from "react";
import AppcheckBase from "@src/layouts/AppcheckBase";
import queryString from "query-string";
import window from "global/window";

function AppcheckTeacherClassStory() {
  const parsedLocation = queryString.parse(window?.location?.search || "");
  const { classId } = parsedLocation;

  return (
    <AppcheckBase>
      <a
        id="app-trigger"
        href={`https://teach.classdojo.com/#/classes/${classId}/story`}
        data-app={`https://teach.classdojo.com/#/classes/${classId}/story`}
        data-app-ios={`classdojo://t/c/${classId}/st`}
        data-app-android="https://bnc.lt/open-or-download-app"
        data-store-android="com.classdojo.android"
        data-store-ios="552602056"
        style={{ color: "#fff" }}
      >
        ClassDojo App
      </a>
    </AppcheckBase>
  );
}

export default AppcheckTeacherClassStory;
